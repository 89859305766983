import makeFAIcon from "utils/makeFAIcon";

import {
  faPhone,
  faEnvelope,
  faPlus,
  faBars,
  faTimes,
  faGlobe,
  faPlay,
  faBookReader,
  faMapMarker,
  faVideo,
  faUserFriends,
  faHandHoldingHeart,
  faBriefcase,
  faProjectDiagram,
  faCommentDots,
  faDiceTwo,
  faForward,
  faCircleNotch,
  faChevronLeft,
  faSortNumericUp,
  faSun,
  faChalkboardTeacher,
  faHands,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebookF,
  faLinkedinIn,
  faGithubAlt,
  faMediumM,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export const PhoneIcon = makeFAIcon(faPhone);
export const EnvelopIcon = makeFAIcon(faEnvelope);
export const PlusIcon = makeFAIcon(faPlus);
export const BarsIcon = makeFAIcon(faBars);
export const GithubIcon = makeFAIcon(faGithubAlt);
export const MediumIcon = makeFAIcon(faMediumM);
export const CloseIcon = makeFAIcon(faTimes);
export const LanguageIcon = makeFAIcon(faGlobe);
export const PlayIcon = makeFAIcon(faPlay);
export const ReadIcon = makeFAIcon(faBookReader);
export const PointerIcon = makeFAIcon(faMapMarker);
export const VideoIcon = makeFAIcon(faVideo);
export const PeopleIcon = makeFAIcon(faUserFriends);
export const HeartIcon = makeFAIcon(faHandHoldingHeart);
export const WorkIcon = makeFAIcon(faBriefcase);
export const ProjectIcon = makeFAIcon(faProjectDiagram);
export const QuestionsIcon = makeFAIcon(faCommentDots);
export const ChoicesIcon = makeFAIcon(faDiceTwo);
export const UrgentIcon = makeFAIcon(faForward);
export const CircleIcon = makeFAIcon(faCircleNotch);
export const BackIcon = makeFAIcon(faChevronLeft);
export const NumbersIcon = makeFAIcon(faSortNumericUp);
export const SumIcon = makeFAIcon(faSun);
export const ClassIcon = makeFAIcon(faChalkboardTeacher);
export const HandsIcon = makeFAIcon(faHands);

export const TwitterIcon = makeFAIcon(faTwitter);
export const FacebookIcon = makeFAIcon(faFacebookF);
export const LinkedinIcon = makeFAIcon(faLinkedinIn);
export const YoutubeIcon = makeFAIcon(faYoutube);
export const InstagramIcon = makeFAIcon(faInstagram);

export * from "config/CustomIcons";
