import React from "react";
import PropTypes from "prop-types";
import clsx from 'clsx';

import { Link } from "gatsby";
import * as SocialIcons from "components/SocialIcons";

import { Row, Col, Button } from "react-bootstrap";
import Icon from "components/Icon";
import Bio from "components/Bio";
import PageSection from "components/PageSection";
import SectionHeader from "components/SectionHeader";
import "./Contact.scss";

const Contact = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    rootSubHeader,
    subheader,
    description,
    social,
    imageFileName,
    button,
    redirect,
    title,
    telephone,
    email,
    social: { youtube, facebook, instagram },
  } = frontmatter;

  const youtubePart = youtube ? <SocialIcons.Youtube userName={youtube} /> : null;
  const facebookPart = facebook ? <SocialIcons.Facebook userName={facebook} /> : null;
  const instagramPart = instagram ? <SocialIcons.Instagram userName={instagram} /> : null;

  return (
    <>
      <PageSection className={clsx("contact", className)} id='contact'>
        <Row>
          <SectionHeader className="section-header-alt" header={rootHeader} subheader={rootSubHeader} />
        </Row>
        <Row className="mx-auto text-center">
          <Col className="profile-container">
            <Bio name={subheader} description={description} social={social} imageFileName={imageFileName} />
          </Col>
        </Row>
        <Row className="mx-auto text-center">
          <Col>
            <Link to={redirect}>
              <Button className="mt-4">{button}</Button>
            </Link>
          </Col>
        </Row>
        <Row className="mx-auto text-center social">
          {youtubePart}
          {facebookPart}
          {instagramPart}
        </Row>
      </PageSection>
      <PageSection className="bg-light">
        <Row className="justify-content-center subtitle">
          <Col lg={8} className="text-center">
            <hr className="divider my-4" />
            <h2 className="mt-0">{title}</h2>
            <hr className="divider my-4" />
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="ml-auto mt-3 text-center">
            <Icon iconName="PhoneIcon" size="3x" className="text-muted mb-3" />
            <a className="d-block" href={`tel:${telephone}`}>
              {telephone}
            </a>
          </Col>
          <Col lg={4} className="mr-auto mt-3 text-center">
            <Icon iconName="EnvelopIcon" size="3x" className="text-muted mb-3" />
            <a className="d-block" href={`mailto:${email}`}>
              {email}
            </a>
          </Col>
        </Row>
      </PageSection >
    </>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Contact;
