import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import Icon from "components/Icon";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

import splitTextByLine from 'utils/formatText';

import "./Consultations.scss";

const Consultations = ({ frontmatter, langKey }) => {
    if (!frontmatter) {
        return null;
    }

    const { anchor, header: rootHeader, italic, description, button, whatsappLink, whatsappMessage, types } = frontmatter;

    const italictSplit = splitTextByLine(italic);
    const descriptionSplit = splitTextByLine(description);
    const whatsappMessageEncoded = whatsappMessage ? encodeURI(whatsappMessage) : '';

    return (
        <div className="section-wrapper consultations">
            <PageSection className="description bg-light" id={anchor} showBackButton langKey={langKey}>
                <SectionHeader header={rootHeader} />
                <Row className="mt-1">
                    <Col>
                        {italictSplit.map(text =>
                            <div key={text}>
                                <span>{text}</span><br />
                            </div>
                        )}
                        {descriptionSplit.map(text => <p className="mt-3" key={text}>{text}</p>)}
                    </Col>
                    <Col className="whatsapp-col my-auto" sm={3} md={4}>
                        <a className="whatsapp-btn btn" href={whatsappLink + whatsappMessageEncoded} target="_blank" rel="noreferrer">
                            {button}
                        </a>
                    </Col>
                </Row>
            </PageSection >
            <svg className="svg-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 110 1440 320" transform="scale(1,-1)">
                <path fill="#FF6149" d="M0,160L48,160C96,160,192,160,288,176C384,192,480,224,576,218.7C672,213,768,171,864,144C960,117,1056,107,1152,112C1248,117,1344,139,1392,149.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z" />
            </svg>
            <div className="consultations">
                <Row className="mt-2 p-3 d-flex justify-content-around">
                    {types.map(type =>
                        <Col className="consultation-type mb-2" sm={12} md={6} lg={4} key={type}>
                            <div className="type-container" style={{ border: `3px solid ${type.color}` }}>
                                <svg className="svg-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" transform="scale(1,-1)">
                                    <path fill={type.color} d="M0,160L48,160C96,160,192,160,288,176C384,192,480,224,576,218.7C672,213,768,171,864,144C960,117,1056,107,1152,112C1248,117,1344,139,1392,149.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z" />
                                </svg>
                                <Icon className="icon" iconName={type.icon} size="3x" style={{ color: type.color }} />
                                <h4>{type.title}</h4>
                                <h6>{type.subtitle}</h6>
                                <div className="content">
                                    <div className="body">
                                        <div>
                                            <p>{type.description}</p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <Icon className="bullet" iconName="CircleIcon" style={{ color: type.color }} />
                                            <p>{type.bullet}</p>
                                        </div>
                                    </div>
                                    <div className="footer">
                                        <div className="message">
                                            {type.wheel && <p className="wheel">{type.wheel}</p>}
                                            <p className="reminder">{type.reminder}</p>
                                            <p style={{ color: type.color }}><blockquote><strong>{type.italic}</strong></blockquote></p>
                                        </div>
                                        <div>
                                            <a className="type-btn btn" style={{
                                                backgroundColor: type.color,
                                                borderColor: type.color
                                            }}
                                                href={type.buttonLink} target="_blank" rel="noreferrer"
                                            >
                                                {type.button}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    );
};

Consultations.propTypes = {
    frontmatter: PropTypes.object,
    langKey: PropTypes.string,
};

Consultations.defaultProps = {
    frontmatter: null,
    langKey: `${process.env.DEFAULT_LANG}`,
};

export default Consultations;
