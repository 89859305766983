import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import * as Sections from "views/Sections";
import Layout from "components/Layout"

import breakDownAllNodes from "utils/breakDownAllNodes";
import fileNameToSectionName from "utils/fileNameToSectionName";
import cardKeyToMetadata from "utils/cardKeyToMetadata";

function mapCards(c, pathContext, hasCardsText) {
  const metadata = cardKeyToMetadata(c.chaveCarta);

  const cardContent = {
    ...metadata,
    id: c.id,
    name: pathContext.langKey === 'fr' ? c.nomeCartaFr : c.nomeCartaPt,
  }

  if (hasCardsText) {
    cardContent.text = pathContext.langKey === 'fr' ? c.mensagemCartaFr : c.mensagemCartaPt;
  }

  return cardContent;
}

function mapSection(allConteudoPaginasList, section, langKey) {
  const content = allConteudoPaginasList.find(c => section.fields.fileName.includes(c.id + '.'));

  if (content == null) {
    return section;
  }

  const langKeyCaptalized = langKey.charAt(0).toUpperCase() + langKey.slice(1)
  section.frontmatter.header = content['titulo' + langKeyCaptalized];
  section.frontmatter.subheader = content['subtitulo' + langKeyCaptalized];
  section.frontmatter.description = content['descricao' + langKeyCaptalized];
  section.frontmatter.button = content['botao' + langKeyCaptalized];
  section.frontmatter.redirect = content.botaoLink;

  return section;
}


/**
 * get file name list from content/sections folder
 */
export const query = graphql`
  query IndexQuery($langKey: String!) {
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          path
          brand
          button
          anchor
          home
          copyright
          header
          email
          imageFileName
          menuText
          social {
            facebook
            youtube
            instagram
            medium
          }
          subheader
          # name
          description
          telephone
          title
          # timeline {
          #  content
          #  header
          #  imageFileName
          #  subheader
          # }
          # jobs {
          #  title
          #  text
          #  redirect
          #  icon
          # }
          # videosHeader
          # youtubeChannelId
          # youtubeApiKey
          # blogUrl
          # articlesHeader
          # cards {
          #  name
          #  imageFileName
          #  text
          #  thinkLabel
          #  thinkText
          # }
          hasCards
          hasCardsText
          hasPremiumCards
          redirect
          password
          wrongPassword
          # italic
          # whatsappLink
          # whatsappMessage
          # whatsappButton
          # types {
          #  title
          #  icon
          #  color
          #  subtitle
          #  description
          #  bullet
          #  wheel
          #  reminder
          #  italic
          #  button
          #  buttonLink
          # }
          # buttonLink
          # jobTitle
          # articleButton
          # videoButton
          # bullet
          # subtitle
          # icon
          # video
          contactModalUrl
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    postgres {
      allMensagensCartasPagosList {
        id
        chaveCarta
        mensagemCartaFr
        mensagemCartaPt
        nomeCartaFr
        nomeCartaPt
      }
      allMensagensCartasGratisList {
        chaveCarta
        mensagemCartaFr
        mensagemCartaPt
        nomeCartaFr
        nomeCartaPt
      }
      allConteudoPaginasList {
        botaoFr
        botaoPt
        descricaoFr
        descricaoPt
        id
        nodeId
        subtituloFr
        subtituloPt
        tituloFr
        tituloPt
      }
    }
  }
`;

const IndexPage = ({ data, pathContext, path, }) => {
  const {
    allMarkdownRemark: { nodes },
    postgres: { allMensagensCartasPagosList, allMensagensCartasGratisList, allConteudoPaginasList },
  } = data;

  let filteredNodes = []

  if (path === '/' || path === '/' + pathContext.langKey || path === '/' + pathContext.langKey + '/' || path.includes('/' + pathContext.langKey + '#')) {
    filteredNodes = nodes.filter(node => node.frontmatter.home)
  } else {
    filteredNodes = nodes.filter(node => node.frontmatter.path === path)
  }
  const { sectionsNodes } = breakDownAllNodes(filteredNodes);

  const sections = sectionsNodes.map((section, ind) => {
    const mappedSection = mapSection(allConteudoPaginasList, section, pathContext.langKey);
    const { frontmatter, fields: { fileName } } = mappedSection;

    const sectionComponentName = fileNameToSectionName(fileName);

    const SectionComponent = Sections[sectionComponentName];
    if (frontmatter.hasCards != null) {
      frontmatter.cards = allMensagensCartasGratisList.map(c => mapCards(c, pathContext, frontmatter.hasCardsText));
    }
    if (frontmatter.hasPremiumCards != null) {
      frontmatter.cards = allMensagensCartasPagosList.map(c => mapCards(c, pathContext, frontmatter.hasCardsText));
    }

    return SectionComponent ? (
      <SectionComponent
        key={sectionComponentName}
        className={ind % 2 === 0 ? null : "bg-light"}
        frontmatter={frontmatter}
        langKey={pathContext.langKey}
      />
    ) : null;
  })

  return (
    <Layout data={data} pathContext={pathContext} sections={sections} path={path} />
  );
};

IndexPage.propTypes = {
  data: PropTypes.object,
  pathContext: PropTypes.object,
  path: PropTypes.string,
};

IndexPage.defaultProps = {
  data: {
    allMarkdownRemark: {
      nodes: [],
    },
    postgres: {
      allMensagensCartasPagosList: [],
    },
  },
  pathContext: {
    langKey: `${process.env.DEFAULT_LANG}`,
  },
  path: '',
}

export default IndexPage;
