import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col, Button } from "react-bootstrap";
import { Link } from "gatsby"
import SectionHeader from "components/SectionHeader";
import Image from "components/Image";
import PageSection from "components/PageSection";
import "./WheelHome.scss";

const WheelHome = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, button, imageFileName, redirect } = frontmatter;

  return (
    <div className="section-wrapper">
      <svg className="svg-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 110 1440 320">
        <path fill="#FF6149" d="M0,160L48,160C96,160,192,160,288,176C384,192,480,224,576,218.7C672,213,768,171,864,144C960,117,1056,107,1152,112C1248,117,1344,139,1392,149.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z" />
      </svg>
      <PageSection className={clsx("wheelhome-section", className)} id={anchor}>
        <Row>
          <SectionHeader header={rootHeader} subheader={rootSubHeader} />
        </Row>
        <Row>
          <Col>
            <Image className="wheel-image" fileName={imageFileName} alt={imageFileName} />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Link to={redirect}>
              <Button className="wheel-button">{button}</Button>
            </Link>
          </Col>
        </Row>
      </PageSection>
      <svg className="svg-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 110 1440 320" transform="scale(1,-1)">
        <path fill="#FF6149" d="M0,160L48,160C96,160,192,160,288,176C384,192,480,224,576,218.7C672,213,768,171,864,144C960,117,1056,107,1152,112C1248,117,1344,139,1392,149.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z" />
      </svg>
    </div>
  );
};

WheelHome.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

WheelHome.defaultProps = {
  className: null,
  frontmatter: null,
};

export default WheelHome;
