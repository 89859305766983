module.exports = {
  title: {
    fr: "La Roue de Tarot",
    pt: "A Roda do Tarô",
  },
  keywords: [
    "tarot",
    "tarô",
  ],
  description: {
    fr: "Description french",
    pt: "Description pt",
  },
  author: "Clarice Abreu & Mickael Jan",
  trackingId: "UA-119418003-5",
  defaultLang: process.env.DEFAULT_LANG,
  langTextMap: {
    fr: "Français",
    pt: "Português",
  },
};
