import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Container } from "react-bootstrap";

import Icon from "components/Icon";
import getBaseUrl from "utils/getBaseUrl";

import "./PageSection.scss";

const PageSection = ({ children, className, showBackButton, langKey, ...restProps }) => {
  return (
    <section className={clsx("page-section", className)} {...restProps}>
      <Container>
        {showBackButton &&
          <a className="back-btn btn" href={getBaseUrl(langKey)}>
            <Icon iconName="BackIcon" />
            Home
          </a>
        }
        {children}
      </Container>
    </section>
  );
};

PageSection.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  showBackButton: PropTypes.bool,
  langKey: PropTypes.string,
};

PageSection.defaultProps = {
  children: null,
  className: null,
  showBackButton: false,
  langKey: `${process.env.DEFAULT_LANG}`,
};

export default PageSection;
