import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col, Button } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import Icon from "components/Icon";
import PageSection from "components/PageSection";
import "./Wheel.scss";
import CardModal from "components/CardModal";
import AnimatedWheel from "components/AnimatedWheel";
// Causing dependency cycle \/
// import { Jobs } from "..";

const NUMBER_OF_CARDS = 22;

const Wheel = ({ className, frontmatter, langKey, showJobs }) => {
  const { anchor, header: rootHeader, subheader: rootSubHeader, button, modalButton, imageFileName, cards } = frontmatter;

  console.log(rootSubHeader);

  const now = new Date();

  // set the time to be 365 days
  now.setTime(now.getTime() + 365 * 60 * 60 * 24 * 1000);
  if (document && document.location.search.includes("?contact-sent")) {
    document.cookie = `contact-sent=true; expires=${now.toUTCString()}; path=/`;
  }

  const [position, setPosition] = useState({
    currentPosition: 0,
    nextPosition: 0,
  });
  const [card, setCard] = useState(cards[0]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const url = new URL(window.location.href);
  const cardId = url.searchParams.get("id");

  const onClick = React.useCallback(() => {
    setIsModalVisible(false);
    const currentPosition = position.nextPosition;

    const index = cardId !== null ? cardId : Math.floor(Math.random() * cards.length);
    const newCardPosition = cards[index].position;

    const numberOfSectionsToRotate = newCardPosition > card.position ?
      newCardPosition - card.position
      : newCardPosition + (NUMBER_OF_CARDS - card.position);

    const nextPosition = currentPosition + 360 + (numberOfSectionsToRotate * 16.36)

    setPosition({
      currentPosition,
      nextPosition,
    });

    setCard(cards[index]);
    setIsModalVisible(true);
  }, [card, position, cardId, cards]);

  const onCloseModal = React.useCallback(() => {
    setIsModalVisible(false);
  }, []);

  return (
    <div className="section-wrapper wheel-page">
      <PageSection className={clsx("wheel-section bg-light", className)} id={anchor} langKey={langKey}>
        <Row>
          <SectionHeader header={rootHeader} />
        </Row>
        <Row>
          <h4>{rootSubHeader}</h4>
        </Row>
        <Row>
          <Col>
            <div className="wheel-wrapper">
              <Icon iconName="PointerIcon" />
            </div>
            <AnimatedWheel
              imageFileName={imageFileName}
              currentPosition={position.currentPosition}
              nextPosition={position.nextPosition}
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button className="wheel-button" onClick={onClick}>{button}</Button>
          </Col>
        </Row>
        <CardModal
          show={isModalVisible}
          card={card}
          onClose={onCloseModal}
          buttonLabel={modalButton}
          buttonClick={onClick}
        />

      </PageSection>
      <svg className="svg-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 110 1440 320" transform="scale(1,-1)">
        <path fill="#FF6149" d="M0,160L48,160C96,160,192,160,288,176C384,192,480,224,576,218.7C672,213,768,171,864,144C960,117,1056,107,1152,112C1248,117,1344,139,1392,149.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z" />
      </svg>
      {/* {showJobs && <Jobs frontmatter={frontmatter} />} */}
    </div>
  );
};

Wheel.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
  langKey: PropTypes.string,
  showJobs: PropTypes.bool,
};

Wheel.defaultProps = {
  className: null,
  frontmatter: null,
  langKey: `${process.env.DEFAULT_LANG}`,
  showJobs: true,
};

export default Wheel;
