import React from "react";
import PropTypes from "prop-types";

import splitTextByLine from 'utils/formatText';

import "./Bio.scss";

const Bio = ({
  // imageFileName,
  // imageAlt,
  name,
  description,
}) => {
  const descriptionSplit = splitTextByLine(description);

  return (
    <div className="bio">
      {/* <Image
        className="mx-auto circle rounded-circle"
        fileName={imageFileName}
        alt={imageAlt || name || description}
      /> */}
      <h4 className="mb-4">{name}</h4>
      {descriptionSplit.map(text =>
        <div key={text}>
          <span className="text-muted">
            {text}
          </span>
          <br />
        </div>
      )}
    </div>
  );
};

Bio.propTypes = {
  // imageFileName: PropTypes.string.isRequired,
  // imageAlt: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  social: PropTypes.shape({
    youtube: PropTypes.string,
    facebook: PropTypes.string,
    instagram: PropTypes.string,
  }),
};

Bio.defaultProps = {
  // imageAlt: null,
  name: "",
  description: "",
  social: {
    instagram: null,
    facebook: null,
    youtube: null,
  },
};

export default Bio;
