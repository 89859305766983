import React, { useState } from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import Icon from "components/Icon";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

import splitTextByLine from 'utils/formatText';

import "../Numerology/Numerology.scss";

const Birthday = ({ frontmatter, langKey }) => {
    const [videoOpen, setVideoOpen] = useState(false);

    if (!frontmatter) {
        return null;
    }

    const {
        anchor,
        header: rootHeader,
        italic,
        description,
        button,
        buttonLink,
        whatsappLink,
        whatsappMessage,
        whatsappButton,
        bullet,
        icon,
        title,
        subtitle,
    } = frontmatter;

    const descriptionSplit = splitTextByLine(description);
    const bulletSplit = splitTextByLine(bullet)
    const whatsappMessageEncoded = whatsappMessage ? encodeURI(whatsappMessage) : '';

    return (
        <div className="section-wrapper birthday">
            <PageSection className="description bg-light" id={anchor} showBackButton langKey={langKey}>
                <SectionHeader header={rootHeader} />
                <Row className="mt-3 mb-4">
                    <Col>
                        {descriptionSplit.map(text =>
                            <div key={text}>
                                <span className="description">{text}</span><br />
                            </div>
                        )}
                    </Col>
                    <Col className="whatsapp-col my-auto" sm={3} md={4}>
                        <a className="whatsapp-btn btn" href={whatsappLink + whatsappMessageEncoded} target="_blank" rel="noreferrer">
                            {whatsappButton}
                        </a>
                    </Col>
                </Row>
            </PageSection>
            <svg className="svg-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 110 1440 320" transform="scale(1,-1)">
                <path fill="#FF6149" d="M0,160L48,160C96,160,192,160,288,176C384,192,480,224,576,218.7C672,213,768,171,864,144C960,117,1056,107,1152,112C1248,117,1344,139,1392,149.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z" />
            </svg>
            <PageSection>
                <Row className="mt-2 p-3 consultation-type">
                    <div className="type-container">
                        <svg className="svg-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" transform="scale(1,-1)">
                            <path fill="rgb(255, 180, 242)" d="M0,160L48,160C96,160,192,160,288,176C384,192,480,224,576,218.7C672,213,768,171,864,144C960,117,1056,107,1152,112C1248,117,1344,139,1392,149.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z" />
                        </svg>
                        <Icon className="icon" iconName={icon} size="3x" />
                        <h4>{title}</h4>
                        <h6>{subtitle}</h6>
                        {/* <Icon className="video-btn" size="2x" iconName="PlayIcon" onClick={() => setVideoOpen(true)} />
                        <p className="video-text">{video}</p> */}
                        <div className="content">
                            {bulletSplit.map(text => (
                                <div style={{ display: 'flex' }} key={text}>
                                    <Icon className="bullet" iconName="CircleIcon" />
                                    <p>{text}</p>
                                </div>
                            ))}
                            <div className="footer">
                                <p><blockquote><strong>{italic}</strong></blockquote></p>
                                <a className="type-btn btn" href={buttonLink} target="_blank" rel="noreferrer">{button}</a>
                            </div>
                        </div>
                    </div>
                </Row>
                {videoOpen &&
                    <div className="video-player">
                        <Icon className="close-btn" size="3x" iconName="CloseIcon" onClick={() => setVideoOpen(false)} />
                        <iframe title="video" src="//www.youtube.com/embed/1MTkZPys7mU?autoplay=yes&amp;loop=no&amp;controls=no&amp;mute=no&amp;start=0&amp;end=&amp;version=3?autoplay=1" frameBorder="0" allowFullScreen="" />
                    </div>
                }
            </PageSection>
        </div>
    );
};

Birthday.propTypes = {
    frontmatter: PropTypes.object,
    langKey: PropTypes.string,
};

Birthday.defaultProps = {
    frontmatter: null,
    langKey: `${process.env.DEFAULT_LANG}`,
};

export default Birthday;
