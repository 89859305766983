import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import Image from "components/Image";
import "./AnimatedWheel.scss";

const AnimatedWheel = ({ currentPosition, nextPosition, imageFileName }) => {
    const spin = keyframes`
        from {
            transform: rotate(-${currentPosition}deg);
        }
        to {
            transform: rotate(-${nextPosition}deg);
        }
    `;

    const StyledImage = styled(Image)`
        animation: ${spin} 4s ease 0s 1 normal forwards
    `;

    return (
        <StyledImage className='wheel-image' fileName={imageFileName} alt={imageFileName} />
    )

}

const shouldReturnMemo = (prevProps, nextProps) => {
    if (prevProps.currentPosition === nextProps.currentPosition && prevProps.nextPosition === nextProps.nextPosition) {
        return true;
    }
    return false;
}

AnimatedWheel.propTypes = {
    currentPosition: PropTypes.number,
    nextPosition: PropTypes.number,
    imageFileName: PropTypes.string.isRequired,
};

AnimatedWheel.defaultProps = {
    currentPosition: 0,
    nextPosition: 0,
}

export default React.memo(AnimatedWheel, shouldReturnMemo);
