import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row } from "react-bootstrap";

import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import "./Newsletter.scss";

const NewsLetter = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, subheader: rootSubHeader, buttonLink } = frontmatter;

  return (
    <div className="section-wrapper">
      <svg className="svg-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 110 1440 320">
        <path fill="#FF6149" d="M0,160L48,160C96,160,192,160,288,176C384,192,480,224,576,218.7C672,213,768,171,864,144C960,117,1056,107,1152,112C1248,117,1344,139,1392,149.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z" />
      </svg>
      <PageSection className={clsx("newsletter", className)} id={anchor}>
        <SectionHeader subheader={rootSubHeader} />
        <Row className="text-center">
          <iframe title="systemeio-iframe-1634223576266"
            className="newsletterbox"
            width="1024"
            scrolling="no"
            src={buttonLink}
            style={{
              border: 0,
              margin: 0,
              position: 'relative',
              left: '50%',
              transform: 'translateX(-50%)',
            }} />
        </Row>
      </PageSection >
      <svg className="svg-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 110 1440 320" transform="scale(1,-1)">
        <path fill="#FF6149" d="M0,160L48,160C96,160,192,160,288,176C384,192,480,224,576,218.7C672,213,768,171,864,144C960,117,1056,107,1152,112C1248,117,1344,139,1392,149.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z" />
      </svg>
    </div >
  );
};

NewsLetter.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

NewsLetter.defaultProps = {
  className: null,
  frontmatter: null,
};

export default NewsLetter;
