import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Image from "components/Image";
import splitTextByLine from 'utils/formatText';

import { Row, Col, Button, Modal } from "react-bootstrap";
import "./CardModal.scss";

const CardModal = ({ show, card, onClose }) => {
    const [isVisible, setIsVisible] = useState(false);

    const textSplit = splitTextByLine(card.text);
    const thinkTextSplit = splitTextByLine(card.thinkText);

    useEffect(() => {
        if (show)
            setTimeout(() => {
                setIsVisible(true);
            }, 4700);
    }, [show]);

    const onHide = () => {
        setIsVisible(false);
        onClose();
    }

    return (
        <Modal
            className="card-modal"
            onHide={onHide}
            size={card.text ? 'lg' : 'sm'}
            show={isVisible}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{card.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className="card-iamge my-auto" md={card.text ? 6 : 12} lg={card.text ? 3 : 12}>
                        <Image fileName={card.imageFileName} key={card.name} />
                    </Col>
                    {card.text &&
                        <Col className="card-text" md={5} lg={9}>
                            {textSplit.map(text => <p key={text}>{text}</p>)}
                            <span><strong>{card.thinkLabel}</strong></span>
                            {thinkTextSplit.map(text => <span key={text}>{text}</span>)}
                        </Col>
                    }
                </Row >
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onHide}>OK</Button>
            </Modal.Footer>
        </Modal >
    );
}

CardModal.propTypes = {
    show: PropTypes.bool.isRequired,
    card: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default CardModal;
