
const moment = require("moment-timezone");

export default function () {
    const timezone = "America/Sao_Paulo";
    const today = moment.tz(timezone);

    const d = today.format("DD");
    const m = today.format("MM");
    const y = today.format("YYYY");

    return y[0] + d[0] + y[1] + m[0] + y[2] + d[1] + y[3] + m[1];
}