import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import TimelineItem from "components/TimelineItem";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import nl2br from "utils/nl2br";

import "./Timeline.scss";

const Timeline = ({ frontmatter, langKey }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, timeline } = frontmatter;

  const lastTimelineItem = timeline[timeline.length - 1];

  return (
    <div className="section-wrapper timeline-wrapper">
      <PageSection className="description bg-light" id={anchor} showBackButton langKey={langKey}>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </PageSection >
      <svg className="svg-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 110 1440 320" transform="scale(1,-1)">
        <path fill="#FF6149" d="M0,160L48,160C96,160,192,160,288,176C384,192,480,224,576,218.7C672,213,768,171,864,144C960,117,1056,107,1152,112C1248,117,1344,139,1392,149.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z" />
      </svg>
      <PageSection>
        <Row>
          <Col lg={12}>
            <ul className="timeline">
              {timeline.map(({ content, header, imageContent, imageFileName, subheader }, ind) => {
                if (ind === timeline.length - 1) return null;
                return (
                  <TimelineItem
                    invert={ind % 2 === 1}
                    key={header}
                    imageFileName={imageFileName}
                    header={header}
                    subheader={subheader}
                    content={content}
                    imageContent={
                      imageContent ? (
                        <div dangerouslySetInnerHTML={{ __html: `<h4>${nl2br(imageContent)}</h4>` }} />
                      ) : null
                    }
                  />
                )
              })}
            </ul>
            <div className='timeline-last-item'>
              <TimelineItem
                invert={timeline.length % 2 === 0}
                header={lastTimelineItem.header}
                subheader={lastTimelineItem.subheader}
                content={lastTimelineItem.content}
              />
            </div>
          </Col>
        </Row>
      </PageSection >
    </div>
  );
};

Timeline.propTypes = {
  frontmatter: PropTypes.object,
  langKey: PropTypes.string,
};

Timeline.defaultProps = {
  frontmatter: null,
  langKey: `${process.env.DEFAULT_LANG}`,
};

export default Timeline;
