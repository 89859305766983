import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import "./LockedWheel.scss";
import PageSection from "components/PageSection";
import { InputGroup, Button, FormControl } from "react-bootstrap";
import generatePassword from "utils/generatePassword";
import Wheel from '../Wheel';

const LockedWheel = ({ className, frontmatter }) => {
    const { password, wrongPassword, header } = frontmatter;

    const [inputPassword, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [showWheel, setShowWheel] = useState(false);

    const validatePassword = () => {
        if (inputPassword === process.env.WHEEL_PREMIUM_PASSWORD) {
            setShowWheel(true);
        } else {
            setError(true);
        }
    }

    const handleType = (e) => {
        setPassword(e.target.value);
        setError(false);
    }

    const onKeyPress = (e) => {
        if (e.charCode === 13) validatePassword();
    }

    if (showWheel) {
        return (<Wheel frontmatter={frontmatter} showJobs={false} />)
    }

    return (
        <div className="locked-wheel" >
            <PageSection className={clsx("locked-wheel-section", className)}>
                <div className="wrapper">
                    <h2>{header}</h2>
                    <InputGroup className="ml-0">
                        <FormControl
                            placeholder={password}
                            aria-label={password}
                            aria-describedby="basic-addon2"
                            onKeyPress={onKeyPress}
                            onChange={e => handleType(e)}
                        />
                        <InputGroup.Append>
                            <Button variant="outline-secondary" onClick={validatePassword}>OK</Button>
                        </InputGroup.Append>
                    </InputGroup>
                    {error && <p>{wrongPassword}</p>}
                </div>
            </PageSection>
        </div >
    );
};

LockedWheel.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object,
};

LockedWheel.defaultProps = {
    className: null,
    frontmatter: null,
};

export default LockedWheel;
