export default function cardKeyToMetadata(cardKey) {
    switch (cardKey) {
        case 'le_fou':
            return {
                position: 0,
                imageFileName: '/cards/22.jpg',
            };
        case 'le_bateleur':
            return {
                position: 1,
                imageFileName: '/cards/1.jpg',
            };
        case 'la_papesse':
            return {
                position: 2,
                imageFileName: '/cards/2.jpg',
            };
        case 'lemperatrice':
            return {
                position: 3,
                imageFileName: '/cards/3.jpg',
            };
        case 'lempereur':
            return {
                position: 4,
                imageFileName: '/cards/4.jpg',
            };
        case 'le_pape':
            return {
                position: 5,
                imageFileName: '/cards/5.jpg',
            };
        case 'lamoureux':
            return {
                position: 6,
                imageFileName: '/cards/6.jpg',
            };
        case 'le_chariot':
            return {
                position: 7,
                imageFileName: '/cards/7.jpg',
            };
        case 'la_justice':
            return {
                position: 8,
                imageFileName: '/cards/8.jpg',
            };
        case 'lermite':
            return {
                position: 9,
                imageFileName: '/cards/9.jpg',
            };
        case 'la_roue_de_fortune':
            return {
                position: 10,
                imageFileName: '/cards/10.jpg',
            };
        case 'force':
            return {
                position: 11,
                imageFileName: '/cards/11.jpg',
            };
        case 'le_pendu':
            return {
                position: 12,
                imageFileName: '/cards/12.jpg',
            };
        case 'la_mort':
            return {
                position: 13,
                imageFileName: '/cards/13.jpg',
            };
        case 'lemperance':
            return {
                position: 14,
                imageFileName: '/cards/14.jpg',
            };
        case 'le_diable':
            return {
                position: 15,
                imageFileName: '/cards/15.jpg',
            }
        case 'la_maison_dieu':
            return {
                position: 16,
                imageFileName: '/cards/16.jpg',
            };
        case 'lestoille':
            return {
                position: 17,
                imageFileName: '/cards/17.jpg',
            };
        case 'la_lune':
            return {
                position: 18,
                imageFileName: '/cards/18.jpg',
            };
        case 'le_soleil':
            return {
                position: 19,
                imageFileName: '/cards/19.jpg',
            };
        case 'le_jugement':
            return {
                position: 20,
                imageFileName: '/cards/20.jpg',
            };
        case 'le_monde':
            return {
                position: 21,
                imageFileName: '/cards/21.jpg',
            };
        default:
            return {};
    }
}
