import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import "./Jobs.scss";
import Icon from "components/Icon";

const Jobs = ({ className, frontmatter }) => {
    if (!frontmatter) {
        return null;
    }

    const { anchor, jobTitle, jobs } = frontmatter;

    return (
        <PageSection className={clsx("jobs-section", className)} id={anchor}>
            <Row className="mb-3">
                <SectionHeader className="section-header-alt" header={jobTitle} />
            </Row>
            <Row>
                {jobs.map(({ title, text, redirect, icon }) => {
                    const target = redirect.includes('http') ? '_blank' : '_self';

                    return (
                        <Col sm={12} md={6} key={title}>
                            <a className="portfolio-item" href={redirect} target={target}>
                                <span className="caption">
                                    <span className="caption-content">
                                        <Icon className="mb-3" iconName={icon} size="3x" />
                                        <h2>{title}</h2>
                                        <p>{text}</p>
                                    </span>
                                </span>
                            </a>
                        </Col>
                    )
                })}
            </Row>
        </PageSection>
    );
};

Jobs.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object,
};

Jobs.defaultProps = {
    className: null,
    frontmatter: null,
};

export default Jobs;
