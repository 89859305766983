/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { title, keywords, description, author } from "../../config/site";

const SEO = ({ lang }) => {
  const metaTitle = title[lang];
  const metaDescription = description[lang];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={metaTitle}
      meta={
        [
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: author,
          },
          {
            name: `twitter:title`,
            content: metaTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]
          .concat(
            keywords.length > 0
              ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
              : [],
        )
      }
    />
  );
};

SEO.propTypes = {
  lang: PropTypes.string,
};

SEO.defaultProps = {
  lang: `${process.env.DEFAULT_LANG}`,
};

export default SEO;
