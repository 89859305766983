import React from "react";
import PropTypes from "prop-types";

import Navbar from "views/Navbar";
import Footer from "views/Footer";
import SEO from "components/SEO";
import LanguageSelector from "components/LanguageSelector";

import "utils/fixFontAwesome";
import breakDownAllNodes from "utils/breakDownAllNodes";
import "../style/main.scss";

const Layout = ({ data, pathContext: { langKey, defaultLang, langTextMap }, sections, path }) => {
    const {
        allMarkdownRemark: { nodes },
        postgres: { allConteudoPaginasList },
    } = data;

    const mappedNodes = nodes.map(n => {
        const content = allConteudoPaginasList.find(c => n.fields.fileName.includes(c.id + '.'));

        if (content == null || n.frontmatter.anchor == null) {
            return n;
        }

        const langKeyCaptalized = langKey.charAt(0).toUpperCase() + langKey.slice(1)
        n.frontmatter.anchor = content['titulo' + langKeyCaptalized];

        return n;
    })

    const anchorPathMap = [];
    mappedNodes.forEach(n => {
        if (n.frontmatter.anchor != null) {
            const nodePath = n.fields.fileName.includes('Contact') ? n.frontmatter.path + '#contact' : n.frontmatter.path;
            anchorPathMap.push({
                anchor: n.frontmatter.anchor,
                path: nodePath,
            });
        }
    })

    const { navBarNode, anchors, footerNode } = breakDownAllNodes(mappedNodes);

    let langSelectorPart;
    if (langTextMap != null && Object.keys(langTextMap).length > 1) {
        langSelectorPart = (
            <LanguageSelector langKey={langKey} langTextMap={langTextMap} />
        );
    }

    console.log(sections);

    const isLockedWheel = path === "/fr/consultations/roue-du-tarot" || path === "/pt/consultas/roda-do-taro";

    return (
        <>
            <SEO lang={langKey} />

            {!isLockedWheel && < Navbar
                anchors={anchorPathMap}
                frontmatter={navBarNode.frontmatter}
                extraItems={langSelectorPart}
                langKey={langKey}
                defaultLang={defaultLang}
            />}
            {sections}
            <Footer frontmatter={footerNode.frontmatter} />
        </>
    )
}

Layout.propTypes = {
    data: PropTypes.object,
    pathContext: PropTypes.object,
    sections: PropTypes.array,
    path: PropTypes.string,
};

Layout.defaultProps = {
    data: {
        allMarkdownRemark: {
            nodes: [],
        },
    },
    pathContext: {
        langKey: `${process.env.DEFAULT_LANG}`,
        defaultLang: `${process.env.DEFAULT_LANG}`,
        langTextMap: {},
    },
    sections: [],
    path: '',
};

export default Layout;
