import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "gatsby";

import { Row, Col, Button } from "react-bootstrap";
import Bio from "components/Bio";
import PageSection from "components/PageSection";
import SectionHeader from "components/SectionHeader";
import "./About.scss";

const About = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    name,
    description,
    social,
    imageFileName,
    button,
    redirect,
  } = frontmatter;

  return (
    <PageSection className={clsx("about", className)} id={anchor}>
      {/* movi tudo pra contact */}
      <Row>
        <SectionHeader className="section-header-alt" header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row className="mx-auto text-center">
        <Col className="profile-container">
          <Bio name={name} description={description} social={social} imageFileName={imageFileName} />
        </Col>
      </Row>
      <Row className="mx-auto text-center">
        <Col>
          <Link to={redirect}>
            <Button className="mt-4">{button}</Button>
          </Link>
        </Col>
      </Row>
    </PageSection>
  );
};

About.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

About.defaultProps = {
  className: null,
  frontmatter: null,
};

export default About;
